<template>
  <v-app dark>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <div style="width: 100%; text-align: center">
        <img :src="imagelogo" width="100%" />
      </div>
      <div class="pa-2 pr-9" style="background-color: #ccc">
        Hello {{ $store.state.eventInfo.UserName }}
      </div>
      <v-list dense>
        <template v-for="item in Printmenu">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="child.to"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" link :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="clipped"
      dense
      fixed
      app
      flat
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title
        style="text-align: left; font-size: 16px"
        v-text="$store.state.eventInfo.EventName"
      />
      <v-spacer></v-spacer>
      <v-toolbar-title
        style="text-align: left; font-size: 16px"
        v-text="$store.state.eventInfo.VenueName"
      />
      <!-- <v-spacer></v-spacer>
       <v-toolbar-title v-text="$store.state.pageTitle" />
        <v-spacer></v-spacer>
       <v-switch
            v-model="switch1"
            @click="changetheme"
          ></v-switch> -->
    </v-app-bar>
    <v-main>
      <div
        class="pa-2 pl-5"
        style="
          color: navy;
          text-align: left;
          border-bottom: 1px solid #ccc;
          background-color: #e1e1e1;
        "
      >
        {{ $store.state.pageTitle }}
      </div>

      <div style="background-color: #e9e9e9; min-height: 100%">
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer dark class="pt-1 pb-1">
      <v-card width="100%">
        <v-card-actions class="pa-1" style="font-size: 0.7rem">
          <span>&copy; {{ new Date().getFullYear() }}</span>
          <v-spacer></v-spacer>
          Powered By
          <img src="/DNA-Logo-mono.png" height="45px" class="pl-3" alt="" />
        </v-card-actions>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      imagelogo: "",
      switch1: false,
      clipped: false,
      drawer: true,
      fixed: false,
      display_event_name: "",
      display_venue_name: "",

      Printmenu: [
        { icon: "mdi-apps", text: "Dashboard", to: "/print/dashboard" },


{
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Local",
          model: false,
          children: [
             {
              icon: "mdi-printer",
              text: "Print",
              to: "/print/appprint",
            },
             {
          icon: "mdi-printer-check",
          text: "Re-print",
          to: "/print/app-reprint",
        },
         {
              icon: "mdi-barcode-scan",
              text: "Scann",
              to: "/print/localscann",
            },
             {
              icon: "mdi-barcode-scan",
              text: "Re-Scann",
              to: "/print/localrescann",
            },

          ],
        },


        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Central",
          model: false,
          children: [
             {
              icon: "mdi-printer",
              text: "Print",
              to: "/print/appcentralprint",
            },
             {
          icon: "mdi-printer-check",
          text: "Re-Print",
         to: "/print/appcentral-reprint",
        },
         {
              icon: "mdi-barcode-scan",
              text: "Scann",
              to: "/print/centralscann",
            },
            {
              icon: "mdi-barcode-scan",
              text: "Re-Scann",
              to: "/print/centralrescann",
            },

          ],
        },

        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Media",
          model: false,
          children: [
            {
              icon: "mdi-printer",
              text: "Print",
              to: "/print/appmediaprint",
            },
               {
          icon: "mdi-printer-check",
          text: "Re-Print",
           to: "/print/appmedia-reprint",
        },
        {
              icon: "mdi-barcode-scan",
              text: "Scann",
              to: "/print/mediascann",
            },
             {
              icon: "mdi-barcode-scan",
              text: "Re-Scann",
              to: "/print/mediarescann",
            },
          ],
        },


        // {
        //   icon: "mdi-chevron-up",
        //   "icon-alt": "mdi-chevron-down",
        //   text: "Print",
        //   model: false,
        //   children: [
        //     {
        //       icon: "mdi-printer",
        //       text: "Local",
        //       to: "/print/appprint",
        //     },

        //      {
        //       icon: "mdi-printer",
        //       text: "Central",
        //       to: "/print/appcentralprint",
        //     },

        //     {
        //       icon: "mdi-printer",
        //       text: "Media",
        //       to: "/print/appmediaprint",
        //     },
        //   ],
        // },


        //  {
        //   icon: "mdi-chevron-up",
        //   "icon-alt": "mdi-chevron-down",
        //   text: "Reprint",
        //   model: false,
        //   children: [
        //      {
        //   icon: "mdi-printer-check",
        //   text: "Local",
        //   to: "/print/app-reprint",
        // },


        //  {
        //   icon: "mdi-printer-check",
        //   text: "Central",
        //  to: "/print/appcentral-reprint",
        // },

        //       {
        //   icon: "mdi-printer-check",
        //   text: "Media",
        //    to: "/print/appmedia-reprint",
        // },
        //   ],
        // },

        

        // {
        //    icon: "mdi-chevron-up",
        //   "icon-alt": "mdi-chevron-down",
        //   text: "Scan Barcode",
        //   model: false,
        //   children: [
        //     {
        //       icon: "mdi-barcode-scan",
        //       text: "Local",
        //       to: "/print/localscann",
        //     },
        //      {
        //       icon: "mdi-barcode-scan",
        //       text: "Central",
        //       to: "/print/localscann",
        //     },
        //     {
        //       icon: "mdi-barcode-scan",
        //       text: "Media",
        //       to: "/print/mediascann",
        //     },
        //   ]

        // },
       
       
      //  {
      //      icon: "mdi-chevron-up",
      //     "icon-alt": "mdi-chevron-down",
      //     text: "Rescan Barcode",
      //     model: false,
      //     children: [
      //       {
      //         icon: "mdi-barcode-scan",
      //         text: "Local",
      //         to: "/print/localrescann",
      //       },
      //        {
      //         icon: "mdi-barcode-scan",
      //         text: "Central",
      //         to: "/print/localscann",
      //       },
      //       {
      //         icon: "mdi-barcode-scan",
      //         text: "Media",
      //         to: "/print/mediarescann",
      //       },
      //     ]

      //   }




      ],

      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },
  mounted() {
     this.imagelogo = this.$apiPath + "assets/" + this.$store.state.eventInfo.EventUrl + "/logo.jpg";
    if (this.$store.state.eventInfo.UType != "PrintAdmin") {
      this.$store.commit("setLogout");
      this.$router.push("/" + this.$store.state.eventInfo.EventUrl + "/login");
      return;
    }
    this.display_event_name = this.$store.state.eventInfo.EventName;
  },

  methods: {
    changetheme: function () {
      if (this.switch1 == true) {
        this.$vuetify.theme.dark = true;
      }
      if (this.switch1 == false) {
        this.$vuetify.theme.dark = false;
      }
    },
    logout: function () {
      this.$store.commit("setLogout");
      this.$router.push("/" + this.$store.state.eventInfo.EventUrl + "/login");
    },
  },
};
</script>
<style>
.cardHeader {
  background-color: rgb(226, 240, 252);
  padding: 8px;
  padding-left: 10px;
  border-left: 10px solid rgb(75, 35, 250);
  font-weight: 500;
  font-size: 1.05rem;
}
</style>

